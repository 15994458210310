import React from 'react';
import Layout from '../views/template-01/layout';

const NotFoundPage = () => {
    return (
        <Layout>
            <div style={{ minHeight: '500px' }}>NOT FOUND</div>
        </Layout>
    );
};

export default NotFoundPage;
